.container {
    grid-template-rows: auto auto 1fr;
    gap: 1rem;
}

.menu_filter_button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    cursor: pointer;
    border: none;
    outline: none;

}

div:has(input) {
    flex: 1;
}

button:has(svg) {
    margin-right: 0.5rem;

    & svg {
        width: 1.5rem;
        height: 1.5rem;
        /* fill: var(--colorbuttons); */
    }

}

.spacer {
    flex: 1;
    width: 100%;
}

.add_menu_button_container {
    display: flex;
    justify-content: flex-end;
    color: white;
}