@layer reset {

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
}

@layer base {
  :root {
    --color-bg: #fff;
    --color-bg-darker: #f8f8f8;
    --color-text: #333;
    --color-border: #ddd;
    --color-bg-alternate: #f5f5f5;
    --font-primary: 'Roboto', sans-serif;
    --font-secondary: 'Open Sans', sans-serif;
    --font-size-base: 14px;
    --font-size-small: 0.857rem;
    --font-size-large: 1.714rem;
  }


  body,
  #root {
    margin: 0;
    padding: 0;
    font-family: var(--font-primary);
    font-size: var(--font-size-base);
    color: var(--color-text);
    background-color: var(--color-bg);
    min-height: 100%;
    height: 100%;
  }

  #root {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
  }

  header {
    grid-column: 1 /-1;
    background-color: var(--color-bg-darker);
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    h1 {
      font-size: var(--font-size-base);
      font-family: var(--font-secondary);
    }

    .header__nav {
      flex-basis: 100%;
      display: flex;
      justify-content: flex-end;
      padding-bottom: 1rem;
      /* 100% of the available space */

      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        gap: 1rem;

        li {
          a {
            text-decoration: none;
            color: var(--color-text);
            font-size: var(--font-size-base);
          }
        }
      }
    }
  }

  main {
    padding: 1rem;
    background-color: var(--color-bg);
    display: grid;
    padding: 1rem;
    background-color: var(--color-bg);
    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;

    @media (width <=768px) {
      grid-template-columns: 1fr;

    }

    @media (width > 1024px) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  section {
    padding: 1rem;
    border: 1px solid var(--color-border);
    font-size: var(--font-size-base);
  }
}

@layer components {
  .metrics {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;

    .metric {
      display: flex;
      flex-wrap: wrap-reverse;
      background-color: var(--color-bg-alternate);
      padding: 1rem;
      border-radius: 5px;
      text-align: center;

      &>* {
        flex-basis: 100%;
      }

      .metric-number {
        font-size: var(--font-size-large);
        font-weight: bold;
        margin: 0;
      }

      .metric-label {
        font-size: var(--font-size-small);
        margin: 0;
        margin-block-start: 0.5rem;
      }

    }
  }

  table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      border-bottom: 1px solid var(--color-border);
      padding: 0.5rem;
      text-align: left;
    }

    th {
      background-color: var(--color-bg-darker);
    }

    &:has(tbody tr:nth-of-type(3)) {
      tbody tr:nth-of-type(even) {
        background-color: var(--color-bg-alternate);
      }
    }

  }


  #analytics {
    img {
      max-width: 100%;
    }
  }

  footer {
    grid-column: 1 /-1;
    background-color: var(--color-bg-darker);
    padding: 1rem;
    text-align: center;
    font-size: var(--font-size-small);
  }
}

.ant-input {
  border-radius: 0;
  border: 1px solid var(--color-border);
  padding: 0.5rem;
  font-size: 18px !important;
}