.container {
    /* background-color: red; */
    /* outline: 1px solid black; */
    /* padding: 1rem; */
    max-width: 100%;
    grid-template-rows: auto auto 1fr;
}

.menu_filter_button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    cursor: pointer;
    border: none;
    outline: none;

}

div:has(input) {
    flex: 1;
}

button:has(svg) {
    margin-right: 0.5rem;

    & svg {
        width: 1.5rem;
        height: 1.5rem;
        /* fill: var(--colorbuttons); */
    }

}

.spacer {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    /* flex-wrap: wrap - No forget this one na him dey make the flex-basis work */

}

.main_text {
    font-size: 1.5rem;
    flex-basis: 100%;
    color: var(--colorblack);
    padding: 0;
    margin: 0;
}

.filter_text {
    flex: 10%;
    color: var(--colorblack);
    padding-left: 1.5rem;
}

.orderList {
    padding: 0;
    margin: 0;

    @media (width > 768px) {
        min-width: 100%;
        background-color: white;
        display: flex;
        gap: 1rem;
        flex-wrap: wrap;
        flex-direction: row;
    }
}

.push_right {
    @media (width > 768px) {
        max-width: 20%;
    }
}

main {
    @media (width > 768px) {
        grid-template-columns: 1fr;
    }
}

.action_container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}