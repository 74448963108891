.container {
    display: flex;
    justify-content: flex-end;
    gap: 0.4rem;

    * {
        font-size: 18px !important;
    }
}

.no_margin_button {
    margin: 0;
}

.select_container {
    max-width: 50%;

    @media (width > 768px) {
        max-width: 20%;
    }
}