.container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 0.3rem;
}

.search_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 0.3rem;
}

.button {
    background-color: var(--color-primary);
    /* color: white; */
    /* padding: 0.5rem 1rem; */
    border: none;
    /* border-radius: 0.25rem; */
    cursor: pointer;
    outline: none;
}