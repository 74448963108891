.container {
    padding: 20px;
    /* font-family: Arial, sans-serif; */
    margin: 0;
    padding: 0;
    min-width: max-content;

    @media (width > 768px) {
        background-color: white;
    }
}

.order-details {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 20px;
}

.order-details h2 {
    margin-top: 0;
}

.order-details p {
    margin: 5px 0;
}

.order-details .section {
    margin-bottom: 20px;
}

.order-details .section h3 {
    margin-bottom: 10px;
}

.order-details .section p {
    margin: 5px 0;
}

.order-details .sales-item {
    border-top: 1px solid #ddd;
    padding-top: 10px;
    margin-top: 10px;
}

.order-details .sales-item:first-child {
    border-top: none;
    padding-top: 0;
    margin-top: 0;
}

.order-details .actions {
    display: flex;
    justify-content: space-between;
}

.order-details .actions button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.order-details .actions .btn-accept {
    background-color: rgba(57, 47, 47, 1);
    color: white;
}

.order-details .actions .btn-reject {
    background-color: #f44336;
    color: white;
}

/* Flexbox styles for side-by-side layout */
.order-details .flex-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.order-details .flex-container .section {
    flex: 1;
    /* min-width: 200px; */
    /* Adjust as needed */
}

.order-details .status-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.order-details .status-container p {
    margin: 0;
}

.order-details h4 {
    margin: 0;
}