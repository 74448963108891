.container {

    input {
        font-size: 1.5rem !important;
    }

}

.invisible {
    display: none;
}