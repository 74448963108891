.app__header {
    padding-top: 2.5rem;
}

.header__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 1rem 0; */
    min-width: 100%;
}