.container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.4rem;

    * {
        font-size: 18px !important;
    }
}


.select_container {
    max-width: 50%;

    @media (width > 768px) {
        max-width: 30%;
    }
}