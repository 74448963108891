.orderList {
    padding: 0;
    margin: 0;

    @media (width > 768px) {
        min-width: 100%;
        background-color: white;
        display: flex;
        gap: 1rem;
        flex-wrap: wrap;
        flex-direction: row;
    }
}